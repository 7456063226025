<script setup>
// Properties
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
})

// Events
const emit = defineEmits(['custom-blur', 'custom-change'])

// References
const date = ref('')
const focused = ref(false)
const caseValue = ref(false)
if(props.value != undefined && props.value != ''){
  date.value = props.value
  focused.value = true
  caseValue.value = true
}

// Handlers
function handleBlur() {
  // Emit custom event
  emit('custom-blur')
}

function handleFocus() {
  // Change state
  focused.value = true
}

function handleChange() {
  // Emit custom event
  emit('custom-change', props.name, date.value || undefined)
}
</script>

<template>
  <div class="input__date-wrapper">
    <span v-if="!focused && placeholder" class="input__date-placeholder" style="text-transform:none">
      {{ placeholder }}
    </span>
   
    <input
    max="9999-12-31"
    type="date"
    :class="['input__date', { 'has-error': error }]"
    :title="placeholder"
    required
    @blur="handleBlur"
    @focus="handleFocus"
    @change="handleChange"
    v-model="date"
  />
   
  </div>
</template>
